const BASE_WALLET = [
  {
    value: 1,
    label: "Wallet #1",
  },
  {
    value: 2,
    label: "Wallet #2",
  },
];

export default BASE_WALLET;
