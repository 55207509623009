import axios from "axios";

const rootUrl = "/api/crypto-wallet/";

// ANCHOR get assets
export const getAssets = (params) => {
  const url = `${rootUrl}assets`;
  const response = axios.get(url, { params });

  return response;
};

// ANCHOR get address
export const getAddress = (params) => {
  const url = `${rootUrl}address`;
  const response = axios.get(url, { params });

  return response;
};

// ANCHOR get eth history
export const getEthHistory = (params) => {
  const url = `${rootUrl}history/eth`;
  const response = axios.get(url, { params });

  return response;
};

// ANCHOR get eth history
export const getErc20History = (params) => {
  const url = `${rootUrl}history/erc20`;
  const response = axios.get(url, { params });

  return response;
};

// ANCHOR get swap history
export const getSwapHistory = (params) => {
  const url = `${rootUrl}swap/history`;
  const response = axios.get(url, { params });

  return response;
};

// ANCHOR get coin fee
export const getEthFee = (params) => {
  const url = `${rootUrl}send/eth/estimate-fee`;
  const response = axios.post(url, params);

  return response;
};

// ANCHOR get token fee
export const getTokenFee = (params) => {
  const url = `${rootUrl}send/token/estimate-fee`;
  const response = axios.post(url, params);

  return response;
};

// ANCHOR get matic fee
export const getMaticFee = (params) => {
  const url = `${rootUrl}send/matic/estimate-fee`;
  const response = axios.post(url, params);

  return response;
};
// ANCHOR withdraw token
export const withdrawToken = (params) => {
  const url = `${rootUrl}send/token`;
  const response = axios.post(url, params);

  return response;
};

// ANCHOR withdraw token
export const withdrawCoin = (params) => {
  const url = `${rootUrl}send/eth`;
  const response = axios.post(url, params);

  return response;
};

// ANCHOR withdraw matic
export const withdrawMatic = (params) => {
  const url = `${rootUrl}send/matic`;
  const response = axios.post(url, params);

  return response;
};

// ANCHOR estimate swap
export const estimateSwap = (params) => {
  const url = `${rootUrl}swap/estimate`;
  const response = axios.post(url, params);

  return response;
};

// ANCHOR get matic history
export const getMaticHistory = (params) => {
  const url = `${rootUrl}history/matic`;
  const response = axios.get(url, { params });

  return response;
};

// ANCHOR swap
export const swap = (params) => {
  const url = `${rootUrl}swap`;
  const response = axios.post(url, params);

  return response;
};
