import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUser } from "@apis/user";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "@store/auth";
import { getAddress } from "@apis/crypto-wallet";
import {
  setWalletAddress,
  setEthBalance,
  setTokenBalance,
  setTotalDoller,
  setTotalEthDoller,
  setTotalCodaDoller,
  setTotalUSDTDoller,
  setUSDTBalance,
  setSendOuterBalance,
  setMaticBalance,
  setTotalMaticDoller,
} from "@store/wallet";

import { setLoading } from "@store/global";

// Redux
import { useSelector } from "react-redux";

// API
import { getAssets } from "@apis/crypto-wallet";

const useAuthRoute = () => {
  const authRoutes = [
    "/sign-in",
    "/sign-up",
    "/verification",
    "/find-password",
    "/complete-password-reset",
    "/complete-sign-up",
  ];
  const location = useLocation();
  const [isAuthRoute, setIsAuthRoute] = useState(false);

  const { baseWallet } = useSelector((state) => state.wallet);

  // ** Hooks
  const navigate = useNavigate();

  // ** Dispatch
  const dispatch = useDispatch();

  // ** Session check
  const sessionCheck = async (loading = false) => {
    try {
      const { data: res } = await getUser();

      if (res.statusCode === 200) {
        const params = {
          walletId: baseWallet,
        };

        const { data: res2 } = await getAddress(params);
        if (res2.statusCode === 200) {
          dispatch(setWalletAddress(res2.data.walletAddress));
          dispatch(setUser(res.data));
          getAsset(loading);
        }
      }
    } catch (err) {
      navigate("/sign-in", { replace: true });
    }
  };

  // ** get Assets
  const getAsset = async (loading = false) => {
    try {
      const params = {
        walletId: baseWallet,
      };

      if (loading) {
        dispatch(setLoading(true));
      }

      const { data: res } = await getAssets(params);
      if (res.statusCode === 200) {
        dispatch(setEthBalance(res.data.ethBalance));
        dispatch(setTokenBalance(res.data.tokenBalance));
        dispatch(setTotalDoller(res.data.totalDoller));
        dispatch(setTotalEthDoller(res.data.totalEthDoller));
        dispatch(setTotalCodaDoller(res.data.totalCODADoller));
        dispatch(setTotalUSDTDoller(res.data.totalUSDTDoller));
        dispatch(setUSDTBalance(res.data.usdtBalance));
        dispatch(setSendOuterBalance(res.data.sendOuterBalance));
        dispatch(setMaticBalance(res.data.maticBalance));
        dispatch(setTotalMaticDoller(res.data.totalMaticDoller));
      }
    } catch (err) {
      navigate("/sign-in", { replace: true });
    } finally {
      if (loading) {
        dispatch(setLoading(false));
      }
    }
  };

  useEffect(() => {
    const isAuthRoute = authRoutes.includes(location.pathname);
    setIsAuthRoute(isAuthRoute);

    if (!isAuthRoute) {
      sessionCheck();
    }

    // return () => {
    //   setIsAuthRoute(false);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const isAuthRoute = authRoutes.includes(location.pathname);
    setIsAuthRoute(isAuthRoute);

    if (!isAuthRoute) {
      sessionCheck(true);
    }

    // return () => {
    //   setIsAuthRoute(false);
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseWallet]);

  return isAuthRoute;
};

export default useAuthRoute;
