const LINKS = [
  { path: "/", mobile: "Home", desktop: "Dashboard", icon: "home" },
  { path: "/deposit", desktop: "Deposit", icon: "deposit" },
  { path: "/withdraw", desktop: "Withdraw", icon: "withdraw" },
  // { path: "/swap", desktop: "Swap", icon: "trade" },
  { path: "/history", desktop: "History", icon: "actions" },
  // { path: "/staking", desktop: "Staking", icon: "wallet" },
  // { path: "/trade", desktop: "Trade", icon: "trade" },
  // { path: "/actions", desktop: "Actions", icon: "actions" },
  // { path: "/wallet", desktop: "Wallet", icon: "wallet" },
  // { path: "/nft", desktop: "NFT", icon: "nft" },
  {
    path: "/collections",
    mobile: "Explore",
    desktop: "Collections",
    icon: "collections",
  },
];

export default LINKS;
