import axios from "axios";

const rootUrl = "/api/user/";

// ANCHOR get user
export const getUser = () => {
  const url = `${rootUrl}`;
  const response = axios.get(url);

  return response;
};

// ANCHOR signup
export const signUp = (params, headers) => {
  const url = `${rootUrl}signUp`;
  const response = axios.post(url, params, { headers });

  return response;
};

// ANCHOR create QRCODE
export const createOtpQRCode = () => {
  const url = `${rootUrl}otp/qr-code`;
  const response = axios.get(url);

  return response;
};

// ANCHOR Activate OTP
export const activateOTP = (params) => {
  const url = `${rootUrl}otp/active`;
  const response = axios.post(url, params);

  return response;
};

// ANCHOR check Activate OTP
export const isActiveOtp = () => {
  const url = `${rootUrl}otp/is-active`;
  const response = axios.get(url);

  return response;
};

// ANCHOR change password
export const changePassword = (params) => {
  const url = `${rootUrl}password/change`;
  const response = axios.post(url, params);

  return response;
};

// ANCHOR get account list
export const getAccountList = (params) => {
  const url = `${rootUrl}getAccountList`;
  const response = axios.get(url, { params });

  return response;
};

// ANCHOR find password
export const findPassword = (params, headers) => {
  const url = `${rootUrl}password/find`;
  const response = axios.post(url, params, { headers });

  return response;
};

// ANCHOR get nft
export const getNft = (params) => {
  const url = `${rootUrl}nft`;
  const response = axios.get(url, { params });

  return response;
};

// ANCHOR reset password
export const resetPassword = (params) => {
  const url = `${rootUrl}password/reset`;
  const response = axios.get(url, { params });

  return response;
};

// ANCHOR complete sign up
export const completeSignUp = (params) => {
  const url = `${rootUrl}signUp/complete`;
  const response = axios.get(url, { params });

  return response;
};
